import {useFetcher, useLocation, useMatches} from '@remix-run/react';
import {Heading, Button, IconCheck, Popup} from '~/components';
import {useCallback, useEffect, useRef} from 'react';
import {useInView} from 'react-intersection-observer';
import {CartAction} from '~/lib/type';
import {DEFAULT_LOCALE} from '~/lib/utils';
import clsx from 'clsx';
import {countries} from '~/data/countries';

export function CountrySelector({cancelPopup}) {
  const [root] = useMatches();
  const fetcher = useFetcher();
  const closeRef = useRef(null);
  const selectedLocale = root.data?.selectedLocale ?? DEFAULT_LOCALE;
  const {pathname, search} = useLocation();
  const pathWithoutLocale = `${pathname.replace(
    selectedLocale.pathPrefix,
    '',
  )}${search}`;

  const defaultLocale = countries?.['default'];
  const defaultLocalePrefix = defaultLocale
    ? `${defaultLocale?.language}-${defaultLocale?.country}`
    : '';

  const {ref, inView} = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const observerRef = useRef(null);
  useEffect(() => {
    ref(observerRef.current);
  }, [ref, observerRef]);

  const closeDropdown = useCallback(() => {
    closeRef.current?.removeAttribute('open');
  }, []);
  const onRegionClick = useCallback(() => {
    localStorage.setItem('hideRegionPopup', true)
  }, []);

  return (
    <Popup cancel={cancelPopup}>
      <div ref={observerRef}>
        <Heading as='h3' size='base'>Please, select your location</Heading>
        <div className="grid gap-5 my-7">
          {countries &&
            Object.keys(countries).map((countryPath) => {
              const countryLocale = countries[countryPath];
              const isSelected =
                countryLocale.language === selectedLocale.language &&
                countryLocale.country === selectedLocale.country;

              const countryUrlPath = getCountryUrlPath({
                countryLocale,
                defaultLocalePrefix,
                pathWithoutLocale,
              });

              return (
                <Country
                  key={countryPath}
                  onRegionClick={onRegionClick}
                  countryUrlPath={countryUrlPath}
                  isSelected={isSelected}
                  countryLocale={countryLocale}
                  cancelPopup={cancelPopup}
                />
              );
            })}
        </div>

      </div>
      {/* {currentCountry.link && currentCountry.link !== currentOrigin ?
        <a href={currentCountry.link} target="_self">
          <button className='block w-full py-5 border-t-[1px] transition hover:text-primary/50'>Choose</button>
        </a>
        :
        <button onClick={() => setShowCountryPopup(false)} className='block w-full py-5 border-t-[1px] transition hover:text-primary/50'>Choose</button>
      } */}
    </Popup>
  );
}

function Country({onRegionClick, countryLocale, countryUrlPath, isSelected, cancelPopup}) {
  return (
    <ChangeLocaleForm
      key={countryLocale.country}
      redirectTo={countryUrlPath}
      buyerIdentity={{
        countryCode: countryLocale.country,
      }}
    >
      {/* <Button
        className={clsx([
          'text-contrast',
          'bg-primary w-full p-2 transition rounded flex justify-start',
          'items-center text-left cursor-pointer py-2 px-4',
        ])}
        type="submit"
        variant="primary"
        onClick={closeDropdown}
      >
        {countryLocale.label}
        {isSelected ? (
          <span className="ml-2">
            <IconCheck />
          </span>
        ) : null}
      </Button> */}
      <button
        type={countryLocale.country !== "RU" ? 'submit' : 'button'}
        className={`font-title text-[#807474] ${isSelected ? 'underline text-primary' : ''} transition hover:text-primary/50`}
        onClick={(e) => {
          if (isSelected) {
            cancelPopup();
          }
          if (countryLocale.country !== "RU") {
            onRegionClick();
          } else {
            window.location.href = "https://antheiawoman.ru";
          }
        }}
      >{countryLocale.label}</button>
    </ChangeLocaleForm>
  );
}

function ChangeLocaleForm({children, buyerIdentity, redirectTo}) {
  const fetcher = useFetcher();

  return (
    <fetcher.Form action="/cart" method="post">
      <input
        type="hidden"
        name="cartAction"
        value={CartAction.UPDATE_BUYER_IDENTITY}
      />
      <input
        type="hidden"
        name="buyerIdentity"
        value={JSON.stringify(buyerIdentity)}
      />
      <input type="hidden" name="redirectTo" value={redirectTo} />
      {children}
    </fetcher.Form>
  );
}

function getCountryUrlPath({
  countryLocale,
  defaultLocalePrefix,
  pathWithoutLocale,
}) {
  let countryPrefixPath = '';
  const countryLocalePrefix = `${countryLocale.language}-${countryLocale.country}`;

  if (countryLocalePrefix !== defaultLocalePrefix) {
    countryPrefixPath = `/${countryLocalePrefix.toLowerCase()}`;
  }
  return `${countryPrefixPath}${pathWithoutLocale}`;
}
